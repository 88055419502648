import { useMemo } from 'react'

export function useUserOrgTraineeColumns() {
  return useMemo(
    () => [
      { headerName: 'Anonymous ID', field: 'anonymousId' },
      { headerName: 'First Name', field: 'firstname', filter: 'agTextColumnFilter' },
      { headerName: 'Last Name', field: 'lastname', filter: 'agTextColumnFilter' },
      { headerName: 'Email', field: 'email', filter: 'agTextColumnFilter' },
      { headerName: 'Cohort(s)', field: 'cohortNames' },
      { headerName: 'ORG/State/UNIT', field: 'organizationName', filter: 'agTextColumnFilter' },
      {
        headerName: 'Institution Access code',
        field: 'accessCodeCode',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Pathway', field: 'trainingPathwayTypeTitle' },
      { headerName: 'Access Code Expire Date', field: 'accessCodeExpiryDate' },
      { headerName: 'Access Code Effective Date', field: 'accessCodeEffectiveDate' },
      { headerName: 'Total Time Online', field: 'totalEngagement' },
    ],
    [],
  )
}
