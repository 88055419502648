export const TERMS_OF_USE = 'https://memorycarepartner.info/terms-of-use/'
export const PRIVACY_POLICY = 'https://memorycarepartner.info/privacy-policy/'
export const MAIN_SITE = 'https://www.memorycarepartner.info/'
export const MAIL_CONTACT = 'mailto: Contact@proactivememoryservices.com'
export const LEARN_MORE_ABOUT_US = 'https://www.proactivememoryservices.com/'

export const routes = {
  signIn: '/',
  signUp: '/sign-up',
  signUpFamily: '/sign-up-family',
  signUpTrainee: '/sign-up-trainee',
  signUpTeamMember: '/sign-up-team-member',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  successSubscription: '/success-subscription',

  // --- User ---
  dashboard: '/journey',
  journey: '/journey',
  journeyItem: '/journey/view/:journeyItemType/:journeyItemId',
  learnLibrary: '/learn-library',
  learnLibraryItem: '/learn-library/view/:learnLibraryItemType/:learnLibraryItemId',
  progress: '/progress',
  actionPlan: '/action-plan',

  // --- Admin ---
  users: '/admin/users',
  userDetails: '/admin/users/:userId',

  accessManagement: '/admin/access-management',

  reports_lessons: '/admin/reports/lessons',
  reports_engagement: '/admin/reports/engagement',
  reports_engagement_user: '/admin/reports/engagement-user/:engagementType',
  reports_engagement_user_session: '/admin/reports/engagement-user/session/:sessionUuid',
  reports_quizzes: '/admin/reports/quizzes',
  reports_questionnaires: '/admin/reports/questionnaires',

  courses: '/admin/lms/courses-and-lessons',

  courseEdit: '/admin/lms/course-edit',
  questionnaireEdit: '/admin/lms/course-edit/questionnaire-content',
  lessonEdit: '/admin/lms/lesson-edit',
  lessonContentEdit: '/admin/lms/lesson-edit/content',
  quizEdit: '/admin/lms/lesson-edit/quiz-content',
  queryEdit: '/admin/lms/query',
  tipEdit: '/admin/lms/tips-edit',

  topicEdit: '/admin/lms/topic-edit',
  learnArticleEdit: '/admin/lms/learn-article-edit',
  timelinePostEdit: '/admin/lms/timeline-post-edit',

  manageLearnLibrary: '/admin/lms/manage-learn-library',
  manageTags: '/admin/lms/manage-tags',
  manageCohorts: '/admin/lms/manage-cohorts',
  manageCarePosts: '/admin/lms/manage-timeline-posts/:type',
  manageQuery: '/admin/lms/manage-query',
  manageTips: '/admin/lms/manage-tips',

  managePathways: '/admin/lms/pathways',
  pathwayEdit: '/admin/lms/pathway',

  questions: '/admin/lms/questions/:type',
  questionEdit: '/admin/lms/question-edit',
  questionAdd: '/admin/lms/questions/add',

  // --- Organization ---
  orgUsers: '/organization/users',
  orgAccessManagement: '/organization/access-management',
  orgReports: '/organization/reports/:type',
  orgTips: '/organization/tips',
}
